@use "sass:math";
@use '~@THC/styles/core' as core;

@use '~@THC/styles/_color' as color;

$unreadIndicatorSize: 16px;
$unreadIndicatorFontSize: 12px;
$unreadIndicatorZIndex: 999;

.unreadMessagesIndicator {
	align-items: center;
	background-color: color.$secondary-red;
	border-radius: 50%;
	color: color.$primary-white;
	display: flex;
	font-size: $unreadIndicatorFontSize;
	height: $unreadIndicatorSize;
	justify-content: center;
	position: absolute;
	right: 0;
	top: -#{core.$space2};
	width: $unreadIndicatorSize;
	z-index: $unreadIndicatorZIndex;
}
